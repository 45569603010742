import React, { useEffect, useState } from 'react'

const Banner = () => {
    const [showMore, setShowMore] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isBannerVisible, setIsBannerVisible] = useState(true);
    
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };

        handleResize(); // Set the initial state
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const toggleShowMore = () => setShowMore(!showMore);
    const closeBanner = () => setIsBannerVisible(false);

    if (!isBannerVisible) return null;

    return (
        <>
            <nav className='w-[100%] max-w-full items-center text-[#18232c] py-2 text-[11px] text-center uppercase justify-center flex bg-[#F3F3F3] relative' style={{ position: "relative", zIndex: 99999, fontFamily: "Epilogue, Sans-serif", fontWeight: 700 }}>
                <button
                    className="absolute top-[4px] right-[15px] text-[16px] text-gray-500 hover:text-black"
                    onClick={closeBanner}
                    style={{
                        position: 'absolute',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                    }}
                >
                    ×
                </button>
                <p className="max-w-[86%] lg:max-w-[65%]">
                    THE WHEEL DEAL LEASING IS NOT A LICENSED NEW MOTOR VEHICLE DEALER.
                    THE WHEEL DEAL LEASING IS AN AUTOMOBILE BROKER. Facility ID #7133814<br/>
                    {isMobile && !showMore ? (
                        <>
                            {" "}
                            <button
                            className="text-blue-500 underline ml-2"
                            onClick={toggleShowMore}
                            >
                            Read More
                            </button>
                        </>
                        ) : (
                        <>
                            {" "}
                            NO WARRANTY REPAIR SERVICES WILL BE PROVIDED BY THE WHEEL DEAL LEASING.
                            THE WHEEL DEAL LEASING DOES NOT CHARGE ANY FEES FOR ITS SERVICES.
                            {isMobile && (
                            <button
                                className="text-blue-500 underline ml-2"
                                onClick={toggleShowMore}
                            >
                                {showMore ? "Read Less" : ""}
                            </button>
                            )}
                        </>
                    )}
                </p>
            </nav>
        </>
    )
}

export default Banner
