import React from "react";
import ContainerWraper from "../components/General/ContainerWraper";
import Breadcrumb from "../components/Blog/Breadcrumb/Breadcrumb";

const Privatepolicy = () => {
  return (
    <>
        <div className="w-full max-w-full flex-col justify-between items-center">
            <ContainerWraper>
                <div className="lg:pl-0">
                    <Breadcrumb crumbs={[{label: "Home", url: "/"}, {label: "Privacy Policy", url: "/privacy-policy"}]} activeCrumb="Privacy Policy" />
                </div>
                <p className="font-bold">General Information</p>
                <p className="mt-3">
                    Effective Date: January 8, 2025 
                </p>
                <p className="mt-3">
                The Wheel Deal Leasing (“us,” “we,” or “our”) operates the website https://thewheeldealleasing.com/ (the “Service”).
                </p>
                <p className="mt-3">
                This Privacy Policy outlines our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used have the same meanings as in our Terms and Conditions, accessible from https://thewheeldealleasing.com/.
                </p>
                <p className="font-bold mt-3">Information Collection and Use</p>
                <p className="mt-3">
                We collect various types of information to provide and improve our Service to you.
                </p>
                <p className="font-bold mt-3">Types of Data Collected:</p>
                <p className="font-bold mt-3">1. Personal Data</p>
                <p className="mt-3">
                While using our Service, we may ask you to provide personally identifiable information, such as:
                </p>
                <p className="mt-3">
                    Email address<br/>
                    First and last name<br/>
                    Phone number<br/>
                    Address, State, Province, ZIP/Postal code, City
                </p>
                <p className="font-bold mt-3">2. Cookies and Usage Data</p>
                <p className="font-bold mt-3">Usage Data</p>
                <p className="mt-3">
                    We may collect information on how the Service is accessed and used (“Usage Data”), including:
                </p>
                <p className="mt-3">
                    Provide and maintain the Service<br/>
                    Notify you about changes to our Service<br/>
                    Allow interactive features when you choose to use them<br/>
                    Provide customer care and support<br/>
                    Analyze and improve the Service<br/>
                    Monitor Service usage<br/>
                    Detect, prevent, and address technical issues
                </p>
                <p className="font-bold mt-3">Transfer of Data</p>
                <p className="mt-3">
                Your information, including Personal Data, may be transferred to computers outside your state, province, or country where data protection laws may differ. By providing your information, you consent to this transfer.
                </p>
                <p className="mt-3">
                We take reasonable steps to ensure your data is secure and no transfer occurs to an organization or country without adequate security controls.
                </p>
                <p className="font-bold mt-3">Disclosure of Data</p>
                <p className="font-bold mt-3">Legal Requirements</p>
                <p className="mt-3">
                We may disclose Personal Data if required to:
                </p>
                <ul className="list-disc ml-4">
                    <li>Comply with a legal obligation</li>
                    <li>Protect our rights or property</li>
                    <li>Prevent wrongdoing in connection with the Service</li>
                    <li>Protect the safety of Service users or the public</li>
                    <li>Defend against legal liability</li>
                </ul>
                <p className="font-bold mt-3">Security of Data</p>
                <p className="mt-3">
                We prioritize data security but cannot guarantee absolute protection due to inherent risks of internet transmission and storage.
                </p>
                <p className="font-bold mt-3">Service Providers</p>
                <p className="mt-3">
                We may employ third-party companies to:
                </p>
                <ul className="list-disc ml-4">
                    <li>Facilitate our Service
                    </li>
                    <li>Provide the Service on our behalf
                    </li>
                    <li>Perform Service-related tasks
                    </li>
                    <li>Analyze Service usage
                    </li>
                </ul>
                <p className="mt-3">
                These third parties access Personal Data only for these purposes and are contractually obligated to maintain confidentiality.
                </p>
                <p className="font-bold mt-3">
                Links to Other Sites
                </p>
                <p className="mt-3">
                Our Service may contain links to third-party websites not operated by us. We recommend reviewing their privacy policies as we are not responsible for their content, policies, or practices.
                </p>
                <p className="font-bold mt-3">
                Children’s Privacy
                </p>
                <p className="mt-3">
                Our Service does not address anyone under 18 years of age. If we learn that a minor has provided us with Personal Data, we will delete the information.
                </p>
                <p className="font-bold mt-3">
                Changes to This Privacy Policy
                </p>
                <p className="mt-3">
                We may update this policy periodically. Changes will be notified via email or a prominent notice on our Service before taking effect. The effective date at the top of this Privacy Policy will also be updated.
                </p>
                <p className="font-bold mt-3">Contact Us</p>
                <p className="mt-3 mb-5">
                    If you have any questions about this Privacy Policy, please contact us:<br/><br/>
                    Email: Tim@thewheeldealleasing.com<br/>
                    Phone: +1 718-228-9999<br/>
                    Address:  2079 Homecrest Ave Brooklyn,  NY 11229
                </p>
            </ContainerWraper>
        </div>
    </>
  );
};

export default Privatepolicy;
