import React, { useState, useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';

const CookiePopup = () => {
  const [isConsentAccepted, setIsConsentAccepted] = useState(false);

  useEffect(() => {
    const consent = Cookies.get('cookieConsent');
    if (consent) {
      setIsConsentAccepted(true);
    }
  }, []);

  return (
    !isConsentAccepted && (
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="cookieConsent"
        onAccept={() => setIsConsentAccepted(true)}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    )
  );
};

export default CookiePopup;