import React from "react";
import ContainerWraper from "../components/General/ContainerWraper";
import Breadcrumb from "../components/Blog/Breadcrumb/Breadcrumb";

const TermsAndConditions = () => {
  return (
    <>
        <div className="w-full max-w-full flex-col justify-between items-center">
            <ContainerWraper>
                <div className="lg:pl-0">
                    <Breadcrumb crumbs={[{label: "Home", url: "/"}, {label: "Terms and Conditions", url: "/terms-and-conditions"}]} activeCrumb="Terms and Conditions" />
                </div>
                <p>Last updated: Jan 8, 2024</p>
                <p className="mt-3">
                    Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before using the https://thewheeldealleasing.com/ website (the “Service”) operated by The Wheel Deal Leasing (“us”, “we”, or “our”).
                </p>
                <p className="mt-3">
                    Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.
                </p>
                <p className="mt-3">
                    By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service. The Terms and Conditions agreement for The Wheel Deal Leasing has been created with the help of TermsFeed.
                </p>
                <p className="font-bold mt-3">Links to Other Websites</p>
                <p className="mt-3">
                    Our Service may contain links to third-party websites or services that are not owned or controlled by The Wheel Deal Leasing.
                </p>
                <p className="mt-3">
                    The Wheel Deal Leasing has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that The Wheel Deal Leasing shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.
                </p>                
                <p className="mt-3">
                    We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.
                </p>
                <p className="font-bold mt-3">Termination</p>
                <p className="mt-3">
                We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
                </p>
                <p className="mt-3">
                All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
                </p>
                <p className="font-bold mt-3">
                    Governing Law
                </p>
                <p className="mt-3">
                These Terms shall be governed and construed in accordance with the laws of New York, United States, without regard to its conflict of law provisions.
                </p>
                <p className="mt-3">
                Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have between us regarding the Service.
                </p>
                <p className="font-bold mt-3">Changes</p>
                <p className="mt-3">
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                </p>
                <p className="mt-3">
                By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
                </p>
                <p className="font-bold mt-3">Contact Us</p>
                <p className="mt-3 mb-5">
                If you have any questions about these Terms, please contact us.
                </p>
            </ContainerWraper>
        </div>
    </>
  );
};

export default TermsAndConditions;
